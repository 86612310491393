.map-view {
  height: 100vh; 
  width: 100vw;
  position: absolute;
  top: 0;
  z-index: 1;
}

.child-view {
  position: relative;
  z-index: 2;
}