
.loading-center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}


.container {
  background-color: #1D1F20;
  position: relative;
  top: 20%;
  margin: auto;
  width: 340px;
  height: 445px;
  border-radius: 0.35em;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
  text-align: center;
}