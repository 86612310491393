$spacing: 8px;
$alignments: left, right, center;
$colors: (
  primary: #1e88e5,
  secondary: #f50057,
  success: #43a047,
  info: #2196f3,
  warning: #ff9800,
  danger: #f44336,
  light: #f8f9fa,
  dark: #212529,
  white: #fff,
  black: #000
);